import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  FloatingLabel,
  Table,
} from "react-bootstrap";

class ApsCalculationResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Card>
          <Card.Header className="bg-success text-light">Results</Card.Header>
          <Card.Body>
            <Card.Text>
              There are currently three main models for calculating analytical performance specifications (APS), i.e., based on direct- and indirect outcome studies, biological variation, and state-of-the-art.
            </Card.Text>
            <Card.Text>
              Studies of the medical requirements (outcome studies) for the measurement results are a primary goal but can be difficult to translate into APS.
            </Card.Text>
              <Card.Text>
              APS based on biological variation (BV) data is commonly used for measurands under homeostatic control. BV data are reference data.  Users of the data from this site should therefore make themselves aware of the confidence limits around the published BV estimates and assure themselves that the data presented are safely transportable and applicable to their local populations and practice. Be aware that some currently available BV estimates may be based on limited data sets therefore can be uncertain. 

            </Card.Text>

            <h5>
              <strong>Imprecision</strong>
            </h5>

            <Card.Text className="font-monospace fw-bold text-center">
              Imprecision: CVA &lt;0.5 x CVI
            </Card.Text>

            <Card.Text>The factor 0.5 refers to desirable APS.</Card.Text>

            <Card.Text>
              The factor for optimum and minimum performance specifications are
              arbitrarily set to 0.25 and 0.75 respectively
            </Card.Text>

            <h5>
              <strong>Bias</strong>
            </h5>

            <Card.Text className="font-monospace fw-bold text-center">
              Bias: &lt; 0.25 × (CVI<sup>2</sup> + CVG<sup>2</sup>)
              <sup>1/2 </sup>
            </Card.Text>

            <Card.Text>
              The formula for bias is intended to be used for laboratories to be
              able to use the same reference limits. The factor 0.25 refers to
              desirable APS. The factor for optimum and minimum performance
              specifications are 0.125 and 0.375, respectively.
            </Card.Text>

            <h5>
              <strong>Maximum allowable measurement uncertainty / Total allowable error</strong>
            </h5>
            <h5>
              <strong>Maximum allowable measurement uncertainty (MAu)</strong>
            </h5>
            <Card.Text>
              Bias should, in principle, be eliminated, and all the remaining sources of variation added linearly as variances. Accordingly, the maximum allowable standard measurement uncertainty (MAu) can be set as 0.5 x CVI, and the maximum expanded allowable measurement uncertainty (MAU) will be k x 0.5 x CVI.  The “k” is the coverage factor, for example, 2 or 3, to obtain a certain confidence level (95 or 99). The most used coverage factor is 2. Thus, MAU can be calculated as 
            </Card.Text>

            <Card.Text className="font-monospace fw-bold text-center">
              MAU  &#60; 2 x 0.5 * CVI
            </Card.Text>

            <Card.Text>
              The factor 0.5 refers to desirable APS. The optimum and minimum performance specifications factor is arbitrarily set to 0.25 and 0.75, respectively. For most measurands, it is for the moment probably best to use the factor 0.75.
            </Card.Text>

            <h5>
              <strong>Total allowable error</strong>
            </h5>
            <Card.Text>
              The formula that considers both bias and imprecision was developed for EQA organizers and has been widely applied since it is easy to use.
            </Card.Text>

            <Card.Text className="font-monospace fw-bold text-center">
              TE &lt; 1.65 × (0.5 x CVI) + 0.25 x (CVI
              <sup>2</sup> + CVG<sup>2</sup>)<sup>1/2</sup>{" "}
            </Card.Text>

            <Card.Text>
              However, this conventional model for deriving total allowable error from biological variation data is flawed. It sums up two mutually exclusive terms, i.e., maximum allowable bias and maximum allowable imprecision, resulting in an overestimating allowable total error. For these reasons, this approach should be applied with caution. 
            </Card.Text>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Specification</th>
                  <th>Imprecision (CVa)</th>
                  <th>BIAS</th>
                  <th>MAU</th>
                  <th>Total Error</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Minimum</td>
                  <td>{this.props.minimumSpecs.minimumCV}</td>
                  <td>{this.props.minimumSpecs.minimumBias}</td>
                  <td>{this.props.minimumSpecs.minimumMAU}</td>
                  <td>{this.props.minimumSpecs.minimumTE}</td>
                </tr>

                <tr>
                  <td>Desirable</td>
                  <td>{this.props.desirableSpecs.desirableCV}</td>
                  <td>{this.props.desirableSpecs.desirableBias}</td>
                  <td>{this.props.desirableSpecs.desirableMAU}</td>
                  <td>{this.props.desirableSpecs.desirableTE}</td>
                </tr>

                <tr>
                  <td>Optimal</td>
                  <td>{this.props.optimumSpecs.optimumCV}</td>
                  <td>{this.props.optimumSpecs.optimumBias}</td>
                  <td>{this.props.optimumSpecs.optimumMAU}</td>
                  <td>{this.props.optimumSpecs.optimumTE}</td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default ApsCalculationResults;
