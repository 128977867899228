import React from "react";
import { Table, Card } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Analyte from "../Analyte/Analyte.jsx";
import Diseases from "../Disease/Diseases";
import StateOfWellBeing from "../StateOfWellBeing/StateOfWellBeing"
import ConfidenceIntervals from "../ui/ConfidenceIntervals.jsx"

class BIVACDatasetItems extends React.Component {
  componentDidMount() {

  }

  matrixExpansion = () => {
    if (this.props.matrix) {
      return this.props.matrix.matrix_expansion;
    }
    return "No matrix defined";
  };

  ethnicityExpansion = () => {
    if (this.props.ethnicity) {
      return this.props.ethnicity.ethnicity_expansion;
    }
    return "No Ethnicity Defined";
  };

  stateOfWellBeing = () => {
    if (this.props.specification.state_of_well_being_id) {
      return <StateOfWellBeing id= {this.props.specification.state_of_well_being_id} />;
    }
    return "No State of Wellbeing Defined";
  };

  render() {
    return (
      <Card className="mx-auto">
        <Card.Title className="text-center bg-primary text-light">
          Dataset Details
        </Card.Title>

        <Table striped bordered>
          <thead>
            <tr>
              <th width="50%">Parameter</th>
              <th width="50%">Score</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Unique Identifier</td>
              <td>{this.props.specification.unique_identifier}</td>
            </tr>

            <tr>
              <td>Matrix</td>
              <td>{<this.matrixExpansion />}</td>
            </tr>
            <tr>
              <td>Measurand</td>
              <td>
                <Analyte
                  id={this.props.specification.analyte_id.toString()}
                  displayFull={true}
                />
              </td>
            </tr>
            <tr>
              <td>Unit</td>
              <td>
                {this.props.specification.unit} /{" "}
                {this.props.specification.second_unit}
              </td>
            </tr>
            <tr>
              <td>Standard Unit for Reporting of Analyte</td>
              <td>
                {
                  this.props.specification
                    .standard_unit_for_reporting_of_analyte
                }{" "}
                / {this.props.specification.second_standard_unit}
              </td>
            </tr>
            <tr>
              <td>Overall Analytical Principle Method</td>
              <td>{this.props.specification.overall_principle_method}</td>
            </tr>

            <tr>
              <td>Number of Subjects</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <NumberFormat
                  value={this.props.specification.number_of_subjects}
                  displayType={"text"}
                  decimalScale="0"
                />
                </td>
            </tr>
            <tr>
              <td >Number of Subjects included in BV estimation</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                {
                  this.props.specification
                    .number_of_subjects_included_in_bv_estimation
                }
              </td>
            </tr>
            <tr>
              <td>Ethnicity</td>
              <td>{<this.ethnicityExpansion />}</td>
            </tr>
            <tr>
              <td>Age Mean / Median</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.age_mean}</td>
            </tr>
            <tr>
              <td>Age Min</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.age_min}</td>
            </tr>
            <tr>
              <td>Age Max</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.age_max}</td>
            </tr>
            <tr>
              <td>Age SD</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.age_sd}</td>
            </tr>
            <tr>
              <td>Number of Males</td>
              <td>{this.props.specification.number_of_males}</td>
            </tr>

            <tr>
              <td>Number of Females</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.number_of_females}</td>
            </tr>
            <tr>
              <td>State of Well Being</td>
              <td>
                <this.stateOfWellBeing />
              </td>
            </tr>

            <tr>
              <td>Diseases</td>
              <td>
                <Diseases id={this.props.specification.id} />
              </td>
            </tr>

            <tr>
              <td>Study Duration Length</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.study_duration}</td>
            </tr>

            <tr>
              <td>Study Duration Units</td>
              <td>{this.props.specification.study_duration_units}</td>
            </tr>
            <tr>
              <td>Sampling Intervals, e.g. X sample(s) per Interval Unit</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.samples_per}</td>
            </tr>
            <tr>
              <td>Interval Units</td>
              <td>{this.props.specification.interval_units}</td>
            </tr>
            <tr>
              <td>Sampling Times</td>
              <td>
                Start Time:
                {this.props.specification.sampling_times_start_time}
                <br />
                End Time:
                {this.props.specification.sampling_times_end_time}
              </td>
            </tr>
            <tr>
              <td>Number of Samples</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.number_of_samples}</td>
            </tr>
            <tr>
              <td>Average Number of Samples Used for BV Estimation</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                {
                  this.props.specification
                    .average_number_of_samples_used_for_bv_estimation
                }
              </td>
            </tr>
            <tr>
              <td>Average Number of Replicates</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.average_number_of_replicates}</td>
            </tr>
            <tr className="table-success">
              <td>Estimates of CVi</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <b>
                  <strong>{this.props.specification.estimates_of_cvi}</strong>
                </b>
              </td>
            </tr>

            {/* <tr>
              <td>Calculated Confidence Intervals of CVi</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <ConfidenceIntervals
                  ciLower={parseFloat(
                    this.props.specification.calculated_within_bv_lower_ci
                  ).toFixed(2)}
                  ciUpper={parseFloat(
                    this.props.specification.calculated_within_bv_upper_ci
                  ).toFixed(2)}
                />
              </td>
            </tr>

            <tr>
              <td>Reported Confidence Intervals of CVi</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <ConfidenceIntervals
                  ciLower={parseFloat(
                    this.props.specification
                      .reported_cvi_lower_confidence_interval
                  ).toFixed(2)}
                  ciUpper={parseFloat(
                    this.props.specification
                      .reported_cvi_upper_confidence_interval
                  ).toFixed(2)}
                />
              </td>
            </tr> */}

            <tr className="table-success">
              <td>Estimates of CVg</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <b>
                  <strong>{this.props.specification.estimates_of_cvg}</strong>
                </b>
              </td>
            </tr>

            <tr>
              <td>Calculated Confidence Intervals of CVg</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <ConfidenceIntervals
                  ciLower={parseFloat(
                    this.props.specification.calculated_between_bv_lower_ci
                  ).toFixed(2)}
                  ciUpper={parseFloat(
                    this.props.specification.calculated_between_bv_upper_ci
                  ).toFixed(2)}
                />
              </td>
            </tr>

            {/* <tr>
              <td>Reported Confidence Intervals of CVg</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <ConfidenceIntervals
                  ciLower={parseFloat(
                    this.props.specification
                      .reported_cvg_lower_confidence_interval
                  ).toFixed(2)}
                  ciUpper={parseFloat(
                    this.props.specification
                      .reported_cvg_upper_confidence_interval
                  ).toFixed(2)}
                />
              </td>
            </tr> */}

            <tr className="table-success">
              <td>Analytical CV</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <b>
                  <strong>{this.props.specification.analytical_cv}</strong>{" "}
                </b>
              </td>
            </tr>

            <tr>
              <td>Reported Confidence Intervals of Analytical CV</td>
              <td className="font-monospace fs-6 text-break text-nowrap">
                <ConfidenceIntervals
                  ciLower={parseFloat(
                    this.props.specification.calculated_analytical_cv_lower_ci
                  ).toFixed(2)}
                  ciUpper={parseFloat(
                    this.props.specification.calculated_analytical_cv_upper_ci
                  ).toFixed(2)}
                />
              </td>
            </tr>

            <tr>
              <td>Measurand Mean</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.concentration_mean}</td>
            </tr>
            <tr>
              <td>Measurand Min</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.concentration_min}</td>
            </tr>
            <tr>
              <td>Measurand Max</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.concentration_max}</td>
            </tr>
            <tr>
              <td>Measurand SD</td>
              <td className="font-monospace fs-6 text-break text-nowrap">{this.props.specification.concentration_sd}</td>
            </tr>
            <tr>
              <td>Measurand Standard Unit Mean</td>
              <td>
                {this.props.specification.concentration_standard_unit_mean}
              </td>
            </tr>
            <tr>
              <td>Measurand Standard Unit Min</td>
              <td>
                {this.props.specification.concentration_standard_unit_min}
              </td>
            </tr>
            <tr>
              <td>Measurand Standard Unit Max</td>
              <td>
                {this.props.specification.concentration_standard_unit_max}
              </td>
            </tr>
            <tr>
              <td>Measurand Standard Unit SD</td>
              <td>{this.props.specification.concentration_standard_unit_sd}</td>
            </tr>
          </tbody>
        </Table>
      </Card>
    );
  }
}

export default BIVACDatasetItems;
